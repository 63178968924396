import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMovie, resetMovie } from '../actions/searchActions'
import { Box, Card, CardContent, CardHeader, Icon, Button, Typography } from '@material-ui/core';
import { MovieList } from '../components/movieList/MovieList';
import { addMovieToFavorits, removeMovieFromFavorits } from '../actions/favoritActions'
import { addMovieToWatchLater, removeMovieFromWatchLater } from '../actions/watchLaterActions'
import MovieCard from '../components/movieCard/MovieCard';

export class ShowMovieContainer extends Component {

  componentDidMount() {
    this.props.getMovie(this.props.paramMovieId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paramMovieId !== this.props.paramMovieId) {
      this.props.getMovie(this.props.paramMovieId);
    }
  }

  componentWillUnmount() {
    this.props.resetMovie();
  }

  navigateToSearch() {
    if (this.props.searchValue) {
      const searchValueEncoded = encodeURIComponent(this.props.searchValue)
      this.props.history.push({
        pathname: '/',
        search: `?query=${ searchValueEncoded }&page=${ this.props.page }`
      });
      return;
    }
    this.props.history.push({ pathname: '/' });
  }

  render() {
    return (
      <Box display="flex" justifyContent="center" ml={ 2 }>
        <Box width="1270px" display="flex" flexDirection="row" flexWrap="wrap" mt={ 2 } >
          <Box minWidth={ '70%' } flexGrow={ 1 } display="flex" flexDirection="column" mr={ 2 } alignItems="center">
            { this.props.movie &&
              <Box width={ 1 } maxWidth={ '870px' } mb={ 2 }>
                <Box mb={ 2 }>
                  <Card>
                    <Box display="flex" flexDirection="row" pt={ 2 } pb={ 2 }>
                      <Box>
                        <Button onClick={ () => { this.navigateToSearch() } }><Icon>keyboard_backspace</Icon></Button>
                      </Box>
                      <Box>
                        <Typography variant="h4">
                          { this.props.movie.title || 'No data' }
                         </Typography>
                      </Box>
                    </Box>
                  </Card>
                </Box>
                <MovieCard
                  movie={ this.props.movie }
                  favoritMovies={ this.props.favoritMovies }
                  watchLaterMovies={ this.props.watchLaterMovies }
                  addMovieToFavorits={ this.props.addMovieToFavorits }
                  addMovieToWatchLater={ this.props.addMovieToWatchLater }
                  removeMovieFromFavorits={ this.props.removeMovieFromFavorits }
                  removeMovieFromWatchLater={ this.props.removeMovieFromWatchLater }/>
              </Box>
            }
          </Box>
          { ((this.props.watchLaterMovies && this.props.watchLaterMovies.length > 0) || (this.props.favoritMovies && this.props.favoritMovies.length > 0)) &&
          <Box minWidth={ '280px' } display="flex" flexGrow={ 1 } flexDirection="column" mr={ 2 } alignItems="center">
            { this.props.watchLaterMovies.length > 0 &&
              <Box width={ 1 } mb={ 2 } maxWidth={ '870px' } id="watchLater">
                <Card>
                  <CardHeader title="Watch later" />
                  <CardContent style={ { maxHeight: '590px', overflowX: 'auto' } }>
                    <MovieList movies={ this.props.watchLaterMovies } removeMovie={ this.props.removeMovieFromWatchLater } />
                  </CardContent>
                </Card>
              </Box>
            }
            { this.props.favoritMovies.length > 0 &&
              <Box width={ 1 } mb={ 2 } maxWidth={ '870px' } id="favoritMovies">
                <Card>
                  <CardHeader title="Favorites" />
                  <CardContent style={ { maxHeight: '590px', overflowX: 'auto' } }>
                    <MovieList movies={ this.props.favoritMovies } removeMovie={ this.props.removeMovieFromFavorits } />
                  </CardContent>
                </Card>
              </Box>
            }
          </Box>
          }
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    paramMovieId: ownProps.match.params.id,
    movie: state.searchReducer.movie,
    favoritMovies: state.favoritReducer.favoritMovies,
    watchLaterMovies: state.watchLaterReducer.watchLaterMovies,
    searchValue: state.searchReducer.searchValue,
    page: state.searchReducer.movies.page
  }
}

export default withRouter(connect(
  mapStateToProps, {
    getMovie,
    addMovieToFavorits,
    removeMovieFromFavorits,
    addMovieToWatchLater,
    removeMovieFromWatchLater,
    resetMovie
  }
)(ShowMovieContainer))
