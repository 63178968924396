import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import './App.css';
import store from './store';
import { Provider } from 'react-redux';
import Header from './components/layout/Header'
import SearchMovieContainer from './containers/SearchMovie';
import ShowMovieContainer from './containers/ShowMovie';
import ErrorAlert from './components/errorAlert/errorAlert';

function App() {
  return (
    <Provider store={ store }>
      <Router>
        <Header />
        <ErrorAlert />
        <Switch>
          <Route exact path="/">
            <SearchMovieContainer />
          </Route>
          <Route exact path="/movie/:id">
            <ShowMovieContainer />
          </Route>
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
