import React, { Component } from 'react'
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Icon, Box, IconButton, TableFooter } from '@material-ui/core';
import { MovieTablePaginator } from './MovieTablePaginator';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { MovieSearchItem } from '../../models/Movie';

export class MovieTable extends Component {

  navigateToMovie(movieId) {
    this.props.history.push(`/movie/${ movieId }`);
  }

  addMovieToFavorits(e, movie) {
    e.stopPropagation();
    this.props.addMovieToFavorits(movie)
  }

  removeMovieFromFavorits(e, movie) {
    e.stopPropagation();
    this.props.removeMovieFromFavorits(movie)
  }

  addMovieToWatchLater(e, movie) {
    e.stopPropagation();
    this.props.addMovieToWatchLater(movie)
  }

  removeMovieFromWatchLater(e, movie) {
    e.stopPropagation();
    this.props.removeMovieFromWatchLater(movie)
  }

  render() {
    const favoritMovieIds = this.props.favoritMovies ? this.props.favoritMovies.map(movie => movie.id) : [];
    const watchLaterMovieIds = this.props.watchLaterMovies ? this.props.watchLaterMovies.map(movie => movie.id) : [];
    return (
      <TableContainer component={ Paper }>
        <Table aria-label="Movie table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell align="left">Title</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { this.props.movies.map(movie => (
              <TableRow className="cursor-pointer" key={ movie.id } onClick={ () => this.navigateToMovie(movie.id) }>
                <TableCell style={{ width: '100px' }} component="th" scope="row">
                  { movie.posterPath !== null
                    ? <img alt={ movie.title } src={ `https://image.tmdb.org/t/p/w92/${ movie.posterPath }` } />
                    : <img alt={ movie.title } src="https://fakeimg.pl/92x138/?text=Placeholder" />
                  }
                </TableCell>
                <TableCell style={{ width: '200px' }} align="left">{ movie.title }</TableCell>
                <TableCell align="right">
                  { favoritMovieIds.includes(movie.id)
                    ? <Box mb={ 4 }><IconButton color="primary" onClick={ (e) => this.removeMovieFromFavorits(e, movie) }><Icon>star</Icon></IconButton></Box>
                    : <Box mb={ 4 }><IconButton color="default" onClick={ (e) => this.addMovieToFavorits(e, movie) }><Icon>star</Icon></IconButton></Box>
                  }
                  { watchLaterMovieIds.includes(movie.id)
                    ? <Box><IconButton color="primary" onClick={ (e) => this.removeMovieFromWatchLater(e, movie) }><Icon>watch_later</Icon></IconButton></Box>
                    : <Box><IconButton color="default" onClick={ (e) => this.addMovieToWatchLater(e, movie) }><Icon>watch_later</Icon></IconButton></Box>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <MovieTablePaginator page={ this.props.page } navigateToPage={ this.props.navigateToPage } totalCount={ this.props.totalCount } />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
}

MovieTable.propTypes = {
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  movies: PropTypes.arrayOf(PropTypes.instanceOf(MovieSearchItem)).isRequired,
  favoritMovies: PropTypes.arrayOf(PropTypes.instanceOf(MovieSearchItem)).isRequired,
  watchLaterMovies: PropTypes.arrayOf(PropTypes.instanceOf(MovieSearchItem)).isRequired,
  addMovieToFavorits: PropTypes.func.isRequired,
  addMovieToWatchLater: PropTypes.func.isRequired,
  removeMovieFromFavorits: PropTypes.func.isRequired,
  removeMovieFromWatchLater: PropTypes.func.isRequired,
  navigateToPage: PropTypes.func.isRequired
};

export default withRouter(MovieTable)
