import React, { Component } from 'react'
import { Card, CardContent, Box, List, ListItem, ListItemText, IconButton, Icon, CardHeader } from '@material-ui/core'
import PropTypes from 'prop-types';
import { convertMovieShowToIndexObject } from '../../helpers/movie'
import { Movie, MovieSearchItem } from '../../models/Movie';

export default class MovieCard extends Component {

  constructor(props) {
    super(props);
    this.iframe = React.createRef();
  }

  removeMovieFromFavorits(movie) {
    this.props.removeMovieFromFavorits(this.props.favoritMovies.find(item => item.id === movie.id));
  }

  addMovieToFavorits(movie) {
    const indexMovieItem = convertMovieShowToIndexObject(movie);
    this.props.addMovieToFavorits(indexMovieItem);
  }

  removeMovieFromWatchLater(movie) {
    this.props.removeMovieFromWatchLater(this.props.watchLaterMovies.find(item => item.id === movie.id));
  }

  addMovieToWatchLater(movie) {
    const indexMovieItem = convertMovieShowToIndexObject(movie);
    this.props.addMovieToWatchLater(indexMovieItem);
  }

  componentDidUpdate(prevProps) {
    if ((this.props.movie.videos[0] && !prevProps.movie.videos[0]) ||
      (this.props.movie.videos[0] && prevProps.movie.videos[0] && this.props.movie.videos[0].key !== prevProps.movie.videos[0].key)
    ) {
      this.iframe.current.contentWindow.location.replace(`https://www.youtube.com/embed/${ this.props.movie.videos[0].key }`);
    }
  }

  componentDidMount() {
    if (this.props.movie.videos[0]) {
      this.iframe.current.contentWindow.location.replace(`https://www.youtube.com/embed/${ this.props.movie.videos[0].key }`);
    }
  }

  render() {
    const favoritMovieIds = this.props.favoritMovies ? this.props.favoritMovies.map(movie => movie.id) : [];
    const watchLaterMovieIds = this.props.watchLaterMovies ? this.props.watchLaterMovies.map(movie => movie.id) : [];
    return (
      <Card>
        <CardHeader action={
          <Box>
            { favoritMovieIds.includes(this.props.movie.id)
              ? <IconButton color="primary" onClick={ (e) => this.removeMovieFromFavorits(this.props.movie) }><Icon>star</Icon></IconButton>
              : <IconButton color="default" onClick={ (e) => this.addMovieToFavorits(this.props.movie) }><Icon>star</Icon></IconButton>
            }
            { watchLaterMovieIds.includes(this.props.movie.id)
              ? <IconButton color="primary" onClick={ (e) => this.removeMovieFromWatchLater(this.props.movie) }><Icon>watch_later</Icon></IconButton>
              : <IconButton color="default" onClick={ (e) => this.addMovieToWatchLater(this.props.movie) }><Icon>watch_later</Icon></IconButton>
            }
          </Box>
        } />
        <CardContent>
          { this.props.movie.videos[0] &&
            <Box mt={ 1 } className="container-trailer">
              <iframe ref={ this.iframe } title={ this.props.movie.title } className="video-trailer" frameBorder="0" allowFullScreen />
            </Box>
          }
          { !this.props.movie.videos[0] && this.props.movie.posterPath !== null
            ? <Box mt={ 1 }><img alt={ this.props.movie.title } src={ `https://image.tmdb.org/t/p/w300/${ this.props.movie.posterPath }` } /></Box>
            : !this.props.movie.videos[0] ? <Box mt={ 1 }><img alt={ this.props.movie.title } src="https://fakeimg.pl/300x423/?text=Placeholder" /></Box> : null
          }
          <Box>
            <List>
              <ListItem>
                <ListItemText
                  primary="Description"
                  secondary={ this.props.movie.overview || '-' } />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Runtime"
                  secondary={ `${ this.props.movie.runtime || '-' } minutes` } />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Release date"
                  secondary={ this.props.movie.releaseDate || '-' } />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Average vote score"
                  secondary={ `${ this.props.movie.voteAverage || '-' } of ${ this.props.movie.voteCount || '-' } votes` } />
              </ListItem>
            </List>
          </Box>
        </CardContent>
      </Card>
    )
  }
}

MovieCard.propTypes = {
  movie: PropTypes.instanceOf(Movie).isRequired,
  favoritMovies: PropTypes.arrayOf(PropTypes.instanceOf(MovieSearchItem)).isRequired,
  watchLaterMovies: PropTypes.arrayOf(PropTypes.instanceOf(MovieSearchItem)).isRequired,
  addMovieToFavorits: PropTypes.func.isRequired,
  addMovieToWatchLater: PropTypes.func.isRequired,
  removeMovieFromFavorits: PropTypes.func.isRequired,
  removeMovieFromWatchLater: PropTypes.func.isRequired,
}
