export class Movie {
  adult = null;
  backdropPath = null;
  belongsToCollection = null;
  budget = null;
  genres = null;
  homepage = null;
  id = null;
  imdbId = null;
  originalLanguage = null;
  originalTitle = null;
  overview = null;
  popularity = null;
  posterPath = null;
  productionCompanies = null;
  productionCountries = null;
  releaseDate = null;
  revenue = null;
  runtime = null;
  spokenLanguages = null;
  status = null;
  tagline = null;
  title = null;
  video = null;
  videos = null;
  voteAverage = null;
  voteCount = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
    this.genres = Array.isArray(this.genres) ? this.genres.map(genreItem => new MovieGenre(genreItem)) : null;
    this.belongsToCollection = this.belongsToCollection ? new MovieBelongsToCollection(this.belongsToCollection) : null;
    this.productionCompanies = Array.isArray(this.productionCompanies) ? this.productionCompanies.map(companyItem => new MovieProductionCompany(companyItem)) : null;
    this.productionCountries = Array.isArray(this.productionCountries) ? this.productionCountries.map(countryItem => new MovieProductionCountry(countryItem)) : null;
    this.spokenLanguages = Array.isArray(this.spokenLanguages) ? this.spokenLanguages.map(languageItem => new MovieSpokenLanguage(languageItem)) : null;
    this.videos = Array.isArray(this.videos) ? this.videos.map(videoItem => new MovieVideo(videoItem)) : null;
  }
}

export class MovieBelongsToCollection {
  backdropPath = null;
  id = null;
  name = null;
  posterPath = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}

export class MovieGenre {
  id = null;
  name = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}

export class MovieProductionCompany {
  id = null;
  logoPath = null;
  name = null;
  originCountry = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}

export class MovieProductionCountry {
  iso31661 = null;
  name = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}

export class MovieSpokenLanguage {
  iso6391 = null;
  name = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}

export class MovieVideo {
  id = null;
  iso6391 = null;
  iso31661 = null;
  key = null;
  name = null;
  site = null;
  size = null;
  type = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}

export class MovieSearchResult {
  page = null;
  results = null;
  totalPages = null;
  totalResults = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}

export class MovieSearchItem {
  adult = null;
  backdropPath = null;
  genreIds = null;
  id = null;
  originalLanguage = null;
  originalTitle = null;
  overview = null;
  popularity = null;
  posterPath = null;
  releaseDate = null;
  title = null;
  video = null;
  voteAverage = null;
  voteCount = null;

  constructor(obj = {}) {
    for (const key in obj) {
      if (this.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    }
  }
}
