import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import favoritReducer from './favoritReducer';
import watchLaterReducer from './watchLaterReducer';

export default combineReducers({
  searchReducer,
  favoritReducer,
  watchLaterReducer
});
