import { ADD_MOVIE_TO_FAVORITS, REMOVE_MOVIE_FROM_FAVORITS } from '../actions/types';

const initalState = {
  favoritMovies: []
};

export default function (state = initalState, action) {
  switch (action.type) {
    case ADD_MOVIE_TO_FAVORITS:
      state.favoritMovies = state.favoritMovies.find(movie => movie.id === action.payload.id)
        ? state.favoritMovies
        : [...state.favoritMovies, action.payload];
      return {
        ...state,
      };
    case REMOVE_MOVIE_FROM_FAVORITS:
      return {
        ...state,
        favoritMovies: state.favoritMovies.filter(movie => movie.id !== action.payload.id)
      };
    default:
      return state;
  }
}
