import { ADD_MOVIE_TO_WATCH_LATER, REMOVE_MOVIE_FROM_WATCH_LATER } from './types';

export const addMovieToWatchLater = movie => dispatch => {
  dispatch({
    type: ADD_MOVIE_TO_WATCH_LATER,
    payload: movie
  });
}

export const removeMovieFromWatchLater = movie => dispatch => {
  dispatch({
    type: REMOVE_MOVIE_FROM_WATCH_LATER,
    payload: movie
  });
}