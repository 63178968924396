import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { MovieSearchItem, Movie } from './models/Movie';

const middleware = [ thunk ];
const restoreState = () => {
  const persistedState = localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')) : {};
  if (persistedState && persistedState.searchReducer) {
    persistedState.searchReducer.movies.results = persistedState.searchReducer ? persistedState.searchReducer.movies.results.map(movie => new MovieSearchItem(movie)) : [];
    persistedState.searchReducer.movie = persistedState.searchReducer.movie !== null ? new Movie(persistedState.searchReducer.movie) : null;
  }
  if (persistedState && persistedState.favoritReducer) {
    persistedState.favoritReducer.favoritMovies = persistedState.favoritReducer ? persistedState.favoritReducer.favoritMovies.map(movie => new MovieSearchItem(movie)) : [];
  }
  if (persistedState && persistedState.watchLaterReducer) {
    persistedState.watchLaterReducer.watchLaterMovies = persistedState.watchLaterReducer ? persistedState.watchLaterReducer.watchLaterMovies.map(movie => new MovieSearchItem(movie)) : [];
  }
  return persistedState;
}
const store = createStore(rootReducer, restoreState(), composeWithDevTools(applyMiddleware(...middleware)));
store.subscribe(() => {
  localStorage.setItem('state', JSON.stringify(store.getState()));
});

export default store;