import { ADD_MOVIE_TO_WATCH_LATER, REMOVE_MOVIE_FROM_WATCH_LATER } from '../actions/types';

const initalState = {
  watchLaterMovies: []
};

export default function (state = initalState, action) {
  switch (action.type) {
    case ADD_MOVIE_TO_WATCH_LATER:
      state.watchLaterMovies = state.watchLaterMovies.find(movie => movie.id === action.payload.id)
        ? state.watchLaterMovies
        : [...state.watchLaterMovies, action.payload];
      return {
        ...state
      };
    case REMOVE_MOVIE_FROM_WATCH_LATER:
      return {
        ...state,
        watchLaterMovies: state.watchLaterMovies.filter(movie => movie.id !== action.payload.id)
      };
    default:
      return state;
  }
}
