import {
  SEARCH_VALUE_FOR_MOVIE,
  GET_MOVIE,
  GET_MOVIES,
  GET_MOVIES_FAILURE,
  SEARCH_INPUT_FOR_MOVIE,
  RESET_SEARCH_MOVIE,
  RESET_MOVIE
} from './types';
import axios from 'axios';
import {
  Movie,
  MovieVideo,
  MovieSpokenLanguage,
  MovieProductionCountry,
  MovieProductionCompany,
  MovieSearchItem,
  MovieSearchResult,
  MovieBelongsToCollection
} from '../models/Movie'

export const setSearchInput = searchInput => dispatch => {
  dispatch({
    type: SEARCH_INPUT_FOR_MOVIE,
    payload: searchInput
  });
}

export const setSearchMovie = searchValue => dispatch => {
  dispatch({
    type: SEARCH_VALUE_FOR_MOVIE,
    payload: searchValue
  });
}

export const getMovies = (searchValue, resultPage = 1) => dispatch => {
  const API_KEY = process.env.REACT_APP_API_KEY;
  const query = encodeURIComponent(searchValue);
  return axios.get(`https://api.themoviedb.org/3/search/movie?api_key=${ API_KEY }&language=en-US&query=${ query }&page=${ resultPage }&include_adult=false`)
    .then(res => {
      const mappedMovies = res.data.results.map(movieItem => {
        const movieSearchItem = new MovieSearchItem(movieItem);
        movieSearchItem.backdropPath = movieItem.backdrop_path;
        movieSearchItem.genreIds = movieItem.genre_ids;
        movieSearchItem.originalLanguage = movieItem.original_language;
        movieSearchItem.originalTitle = movieItem.original_title;
        movieSearchItem.posterPath = movieItem.poster_path;
        movieSearchItem.releaseDate = movieItem.release_date;
        movieSearchItem.voteAverage = movieItem.vote_average;
        movieSearchItem.voteCount = movieItem.vote_count;
        return movieSearchItem;
      });
      const mappedResult = new MovieSearchResult();
      mappedResult.page = res.data.page;
      mappedResult.results = mappedMovies;
      mappedResult.totalPages = res.data.total_pages;
      mappedResult.totalResults = res.data.total_results;
      dispatch({
        type: GET_MOVIES,
        payload: mappedResult
      });
      dispatch({
        type: SEARCH_VALUE_FOR_MOVIE,
        payload: searchValue
      });
      dispatch({
        type: SEARCH_INPUT_FOR_MOVIE,
        payload: searchValue
      });
    })
    .catch(error => {
      const errorMessage = error.response && error.response.data ? error.response.data : {};
      const message =  errorMessage.errors || errorMessage.status_message || 'An error has occured'
      return dispatch({
      type: GET_MOVIES_FAILURE,
      payload: Array.isArray(message) ? message[0] : message
    })}
  );
}

export const resetSearchMovie = () => dispatch => {
  dispatch({
    type: RESET_SEARCH_MOVIE,
    payload: {
      page: null,
      results: [],
      totalPages: null,
      totalResults: null
    }
  });
  dispatch({
    type: SEARCH_VALUE_FOR_MOVIE,
    payload: ''
  });
  dispatch({
    type: SEARCH_INPUT_FOR_MOVIE,
    payload: ''
  });
}

export const getMovie = movieId => dispatch => {
  const API_KEY = process.env.REACT_APP_API_KEY;
  return axios.get(`https://api.themoviedb.org/3/movie/${ movieId }?api_key=${ API_KEY }&language=en-US&append_to_response=videos`)
    .then(res => {
      const movie = new Movie(res.data);
      movie.backdropPath = res.data.backdrop_path;
      movie.imdbId = res.data.imdb_id;
      movie.originalLanguage = res.data.original_language;
      movie.originalTitle = res.data.original_title;
      movie.posterPath = res.data.poster_path;
      movie.releaseDate = res.data.release_date;
      movie.voteAverage = res.data.vote_average;
      movie.voteCount = res.data.vote_count;
      if (res.data.belongs_to_collection) {
        movie.belongsToCollection = new MovieBelongsToCollection(res.data.belongs_to_collection);
        movie.belongsToCollection.backdropPath = res.data.belongs_to_collection.backdrop_path;
        movie.belongsToCollection.posterPath = res.data.belongs_to_collection.poster_path;
      }
      movie.productionCompanies = res.data.production_companies.map(companyItem => {
        const productionCompany = new MovieProductionCompany(companyItem);
        productionCompany.logoPath = companyItem.logo_path;
        productionCompany.originCountry = companyItem.origin_country;
        return productionCompany;
      });
      movie.productionCountries = res.data.production_countries.map(countryItem => {
        const productionCountry = new MovieProductionCountry(countryItem);
        productionCountry.iso31661 = countryItem.iso_3166_1;
        return productionCountry;
      });
      movie.spokenLanguages = res.data.spoken_languages.map(languageItem => {
        const language = new MovieSpokenLanguage(languageItem);
        language.iso6391 = languageItem.iso_639_1;
        return language;
      });
      movie.videos = res.data.videos.results.map(videoItem => {
        const video = new MovieVideo(videoItem);
        video.iso6391 = videoItem.iso_639_1;
        video.iso31661 = videoItem.iso_3166_1;
        return video;
      });
      dispatch({
        type: GET_MOVIE,
        payload: movie
      });
    })
    .catch(error => {
      const errorMessage = error.response && error.response.data ? error.response.data : {};
      const message = errorMessage.errors || errorMessage.status_message || 'An error has occured'
      return dispatch({
        type: GET_MOVIES_FAILURE,
        payload: Array.isArray(message) ? message[0] : message
      })
    });
}

export const resetMovie = () => dispatch => {
  dispatch({
    type: RESET_MOVIE,
    payload: null
  });
}
