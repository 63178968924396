import React, { Component } from 'react'
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { setSearchInput, setSearchMovie, getMovies, resetSearchMovie } from '../actions/searchActions'
import { addMovieToFavorits, removeMovieFromFavorits } from '../actions/favoritActions'
import { addMovieToWatchLater, removeMovieFromWatchLater } from '../actions/watchLaterActions'
import { connect } from 'react-redux';
import MovieTable from '../components/movieTable/MovieTable';
import { SearchForm } from '../components/searchForm/SearchForm';
import { MovieList } from '../components/movieList/MovieList';
import { withRouter } from "react-router-dom";

export class SearchMovieContainer extends Component {
  state = {
    isSubmitCalled: false
  };

  onChangeSearchInput = e => {
    this.props.setSearchInput(e.target.value);
  }

  onSubmitSearch = e => {
    e.preventDefault();
    this.setState({ isSubmitCalled: true })
    const searchValueEncoded = encodeURIComponent(this.props.searchInput)
    this.props.history.push({
      pathname: '/',
      search: `?query=${ searchValueEncoded }&page=1`
    });
  }

  navigateToPage = page => {
    const searchValueEncoded = encodeURIComponent(this.props.searchValue)
    this.props.history.push({
      pathname: '/',
      search: `?query=${ searchValueEncoded }&page=${ page }`
    });
  }

  componentDidMount() {
    if (this.props.resetSearchMovie) {
      this.props.paramQuery
        ? this.props.getMovies(this.props.paramQuery, this.props.paramPage)
        : this.props.resetSearchMovie();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.state.isSubmitCalled ||
      (prevProps.paramQuery !== this.props.paramQuery && this.props.paramQuery !== null) ||
      (prevProps.paramPage !== this.props.paramPage && this.props.paramPage !== null)
    ) {
      this.setState({ isSubmitCalled: false });
      this.props.getMovies(this.props.paramQuery, this.props.paramPage);
    }
  }

  render() {
    return (
      <Box display="flex" justifyContent="center" ml={ 2 }>
        <Box width="1270px" display="flex" flexDirection="row" flexWrap="wrap" mt={ 2 } >
          <Box minWidth={ '70%' } flexGrow={ 1 } display="flex" flexDirection="column" mr={ 2 } alignItems="center">
            <Box width={ 1 } maxWidth={ '870px' } mb={ 2 }>
              <Card>
                <CardHeader title="Welcome to Filmix" />
                <CardContent>
                  <SearchForm onSubmit={ this.onSubmitSearch } onChange={ this.onChangeSearchInput } searchInput={ this.props.searchInput }/>
                </CardContent>
              </Card>
            </Box>
            { (this.props.movies && this.props.movies.length > 0) &&
              <Box width={ 1 } maxWidth={ '870px' } mb={ 2 }>
                <MovieTable
                  totalCount={ this.props.totalResults }
                  page={ this.props.page - 1 }
                  movies={ this.props.movies }
                  favoritMovies={ this.props.favoritMovies }
                  watchLaterMovies={ this.props.watchLaterMovies }
                  addMovieToFavorits={ this.props.addMovieToFavorits }
                  addMovieToWatchLater={ this.props.addMovieToWatchLater }
                  removeMovieFromFavorits={ this.props.removeMovieFromFavorits }
                  removeMovieFromWatchLater={ this.props.removeMovieFromWatchLater }
                  navigateToPage={ this.navigateToPage } />
              </Box>
            }
            { (this.props.searchValue && this.props.movies && this.props.movies.length === 0) &&
              <Box width={ 1 } maxWidth={ '870px' } mb={ 2 } textAlign="center">
                <Card>
                  <CardContent>
                    <Box mt={ 1 }>No result</Box>
                  </CardContent>
                </Card>
              </Box>
            }
          </Box>
          { ((this.props.watchLaterMovies && this.props.watchLaterMovies.length > 0) || (this.props.favoritMovies && this.props.favoritMovies.length > 0)) &&
          <Box minWidth={ '280px' } display="flex" flexGrow={ 1 } flexDirection="column" mr={ 2 } alignItems="center">
            { this.props.watchLaterMovies.length > 0 &&
              <Box width={ 1 } mb={ 2 } maxWidth={ '870px' } id="watchLater">
                <Card>
                  <CardHeader title="Watch later" />
                  <CardContent style={ { maxHeight: '590px', overflowX: 'auto' } }>
                    <MovieList movies={ this.props.watchLaterMovies } removeMovie={ this.props.removeMovieFromWatchLater } />
                  </CardContent>
                </Card>
              </Box>
            }
            { this.props.favoritMovies.length > 0 &&
              <Box width={ 1 } mb={ 2 } maxWidth={ '870px' } id="favoritMovies">
                <Card>
                  <CardHeader title="Favorites" />
                  <CardContent style={ { maxHeight: '590px', overflowX: 'auto' } }>
                    <MovieList movies={ this.props.favoritMovies } removeMovie={ this.props.removeMovieFromFavorits } />
                  </CardContent>
                </Card>
              </Box>
            }
          </Box>
          }
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const params = new URLSearchParams(ownProps.location.search);
  return {
    paramQuery: params.get('query'),
    paramPage: params.get('page'),
    searchValue: state.searchReducer.searchValue,
    searchInput: state.searchReducer.searchInput,
    movies: state.searchReducer.movies.results,
    page: state.searchReducer.movies.page,
    totalResults: state.searchReducer.movies.totalResults,
    favoritMovies: state.favoritReducer.favoritMovies,
    watchLaterMovies: state.watchLaterReducer.watchLaterMovies
  }
}

export default withRouter(connect(
  mapStateToProps, {
    setSearchInput,
    setSearchMovie,
    getMovies,
    addMovieToFavorits,
    removeMovieFromFavorits,
    addMovieToWatchLater,
    removeMovieFromWatchLater,
    resetSearchMovie
  }
)(SearchMovieContainer))
