import React, { Component } from 'react'
import { TablePagination } from '@material-ui/core'
import PropTypes from 'prop-types';
import { MovieTablePaginatiorActions } from './MovieTablePaginatorActions';

export class MovieTablePaginator extends Component {
  render() {
    const handleChangePage = (event, newPage) => {
      this.props.navigateToPage(newPage + 1);
    };
    return (
      <TablePagination
        colSpan={ 3 }
        count={ this.props.totalCount ? this.props.totalCount : 0 }
        rowsPerPageOptions={ [] }
        rowsPerPage={ 20 }
        page={ this.props.page ? this.props.page : 0 }
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onChangePage={ handleChangePage }
        ActionsComponent={ MovieTablePaginatiorActions }
      />
    )
  }
}

MovieTablePaginator.propTypes = {
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  navigateToPage: PropTypes.func.isRequired
};
