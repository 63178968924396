import {
  SEARCH_VALUE_FOR_MOVIE,
  GET_MOVIE,
  GET_MOVIES,
  GET_MOVIES_FAILURE,
  SEARCH_INPUT_FOR_MOVIE,
  RESET_SEARCH_MOVIE,
  RESET_MOVIE
} from '../actions/types';

const initalState = {
  searchInput: '',
  searchValue: '',
  movies: {
    page: null,
    results: [],
    totalPages: null,
    totalResults: null
  },
  movie: null,
  error: null
};

export default function(state = initalState, action) {
  switch (action.type) {
    case SEARCH_VALUE_FOR_MOVIE:
      return {
        ...state,
        searchValue: action.payload
      };
    case SEARCH_INPUT_FOR_MOVIE:
      return {
        ...state,
        searchInput: action.payload
      };
    case GET_MOVIE:
      return {
        ...state,
        movie: action.payload,
        error: null
      };
    case GET_MOVIES:
      return {
        ...state,
        movies: action.payload,
        error: null
      };
    case GET_MOVIES_FAILURE:
      return {
        ...state,
        error: action.payload
      }
    case RESET_SEARCH_MOVIE:
      return {
        ...state,
        movies: action.payload
      }
    case RESET_MOVIE:
      return {
        ...state,
        movie: action.payload
      }
    default:
      return state;
  }
}
