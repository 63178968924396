import React, { Component } from 'react'
import { TextField, Icon, Box, FormControl, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';

export class SearchForm extends Component {
  render() {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box width={ 1 }>
          <form noValidate autoComplete="off" onSubmit={ this.props.onSubmit }>
            <FormControl fullWidth>
              <TextField id="searchValue" value={ this.props.searchInput } placeholder="Search for a movie title..." variant="outlined" aria-label="search-input" onChange={ this.props.onChange } />
            </FormControl>
          </form>
        </Box>
        <Box ml={ 4 }><IconButton onClick={ this.props.onSubmit } aria-label="search-button"><Icon>search</Icon></IconButton></Box>
      </Box>
    )
  }
}

SearchForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  searchInput: PropTypes.string
};
