import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button, Icon, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import { MovieSearchItem } from '../../models/Movie';
import PropTypes from 'prop-types';
import { resetSearchMovie } from '../../actions/searchActions'
import { withRouter } from 'react-router-dom';

export class Header extends Component {

  navigateToHome() {
    this.props.resetSearchMovie();
    this.props.history.push(`/`);
  }

  render() {
    return(
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" className="toolbar-title">
            <span className="cursor-pointer" onClick={ () => { this.navigateToHome() } }>Filmix - Peder Tornberg</span>
          </Typography>
          <Box display={{ xs: 'flex', lg: 'none' }} m={ 0 } p={ 0 } flexDirection="row" flexWrap="nowrap" justifyContent="right">
            { (this.props.favoritMovies && this.props.favoritMovies.length > 0) &&
              <Button href="#favoritMovies" color="inherit"><Icon>star</Icon></Button>
            }
            { (this.props.watchLaterMovies && this.props.watchLaterMovies.length > 0) &&
              <Button href="#watchLater" color="inherit"><Icon>watch_later</Icon></Button>
            }
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = state => {
  return {
    favoritMovies: state.favoritReducer.favoritMovies,
    watchLaterMovies: state.watchLaterReducer.watchLaterMovies
  }
}

Header.propTypes = {
  favoritMovies: PropTypes.arrayOf(PropTypes.instanceOf(MovieSearchItem)),
  watchLaterMovies: PropTypes.arrayOf(PropTypes.instanceOf(MovieSearchItem))
}

export default withRouter(connect(mapStateToProps, { resetSearchMovie })(Header))