export const SEARCH_VALUE_FOR_MOVIE = 'SEARCH_VALUE_FOR_MOVIE';
export const GET_MOVIE = 'GET_MOVIE';
export const GET_MOVIES = 'GET_MOVIES';
export const ADD_MOVIE_TO_FAVORITS = 'ADD_MOVIE_TO_FAVORITS';
export const REMOVE_MOVIE_FROM_FAVORITS = 'REMOVE_MOVIE_FROM_FAVORITS';
export const ADD_MOVIE_TO_WATCH_LATER = 'ADD_MOVIE_TO_WATCH_LATER';
export const REMOVE_MOVIE_FROM_WATCH_LATER = 'REMOVE_MOVIE_FROM_WATCH_LATER';
export const GET_MOVIES_FAILURE = 'GET_MOVIES_FAILURE';
export const SEARCH_INPUT_FOR_MOVIE = 'SEARCH_INPUT_FOR_MOVIE';
export const RESET_SEARCH_MOVIE = 'RESET_SEARCH_MOVIE';
export const RESET_MOVIE = 'RESET_MOVIE';
