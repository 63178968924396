import React, { Component } from 'react'
import { IconButton, Icon, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Avatar, List } from '@material-ui/core'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MovieSearchItem } from '../../models/Movie';

export class MovieList extends Component {
  render() {
    return (
      <List>
        { this.props.movies.map(movie => (
          <ListItem key={ movie.id } button component={ Link } to={ `/movie/${ movie.id }` }>
            { movie.posterPath !== null &&
              <ListItemAvatar>
                <Avatar variant="square" alt={ movie.title } src={ `https://image.tmdb.org/t/p/w92/${ movie.posterPath }` } />
              </ListItemAvatar>
            }
            { movie.posterPath === null &&
              <ListItemAvatar>
                <Avatar variant="square" alt={ movie.title } src="https://fakeimg.pl/92x138/?text=Placeholder" />
              </ListItemAvatar>
            }
            <ListItemText  style={{ width: '180px' }}>{ movie.title }</ListItemText>
            { this.props.removeMovie &&
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={ () => this.props.removeMovie(movie) }><Icon>delete</Icon></IconButton>
              </ListItemSecondaryAction>
            }
          </ListItem>
        ))}
      </List>
    )
  }
}

MovieList.propTypes = {
  movies: PropTypes.arrayOf(PropTypes.instanceOf(MovieSearchItem)).isRequired,
  removeMovie: PropTypes.func
}
