import { ADD_MOVIE_TO_FAVORITS, REMOVE_MOVIE_FROM_FAVORITS } from './types';

export const addMovieToFavorits = movie => dispatch => {
  dispatch({
    type: ADD_MOVIE_TO_FAVORITS,
    payload: movie
  });
}

export const removeMovieFromFavorits = movie => dispatch => {
  dispatch({
    type: REMOVE_MOVIE_FROM_FAVORITS,
    payload: movie
  });
}