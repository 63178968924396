import React, { Component } from 'react'
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export class ErrorAlert extends Component {
  render() {
    return (
      <div>
        { this.props.error !== null &&
          <Alert severity="error">{ this.props.error }</Alert>
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.searchReducer.error
  }
}

ErrorAlert.propTypes = {
  error: PropTypes.string
}

export default connect(mapStateToProps)(ErrorAlert)